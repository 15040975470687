import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useForm, ValidationError } from '@formspree/react';

const buttonStyles = {
  backgroundColor: "#9b3838",
  borderColor: "#9b3838",
  marginTop: "7px",
  marginBottom: "7px",
}

function ContactForm() {
  const [state, handleSubmit] = useForm("mjvzjzzw");
  if (state.succeeded) {
      return <Container><Row className="align-items-center"><h3>Thank you for contacting us!</h3></Row><Row className="align-items-center"><h3>We'll respond to your message as soon as possible.</h3></Row></Container>;
  }
  return (
    <Container>
    <h3>Want to learn more?  Send us a message!</h3>
    <Row className="align-items-center">&nbsp;</Row>
    <form style={{ display: `flex`, flexDirection: `column`, maxWidth: `500px`, }} onSubmit={handleSubmit}>
      <label htmlFor="name">Name:</label>
      <input
        type="text"
        name="name"
        id="name"
        errors={state.errors} />
      <ValidationError
        prefix="Name"
        field="name"
        errors={state.errors} />
      <Row style={{ height: `10px`, }}>&nbsp;</Row>
      <label htmlFor="email">
        Email Address:
      </label>
      <input
        id="email"
        type="email"
        name="email" />
      <ValidationError
        prefix="Email"
        field="email"
        errors={state.errors} />
      <Row style={{ height: `10px`, }}>&nbsp;</Row>
      <label htmlFor="phone">Phone Number:</label>
      <input
        id="phone"
        type="tel"
        name="phone"
        errors={state.errors} />
      <ValidationError
        prefix="Phone"
        field="phone"
        errors={state.errors} />
      <Row style={{ height: `10px`, }}>&nbsp;</Row>        
      <label htmlFor="message">
        Message:
      </label>
      <textarea
        style={{ height: `150px`, }}
        id="message"
        name="message" />
      <ValidationError
        prefix="Message"
        field="message"
        errors={state.errors} />
      <button className="btn btn-primary" style={buttonStyles} type="submit" disabled={state.submitting}>
        Send Message
      </button>
    </form>
    </Container>
  );
}

export default ContactForm;


/*
import React from "react"

const functionURL = "https://us-central1-fieldstoneva-com.cloudfunctions.net/addMessage"
//const functionURL = "http://localhost:5001/fieldstoneva-com/us-central1/addMessage"

// https://us-central1-fieldstoneva-com.cloudfunctions.net/addMessage
// '{"name":"bentz tozer","email":"bentztozer3@gmail.com","phone":"703-555-1212","message":"call me or else!"}'


const buttonStyles = {
    backgroundColor: "#9b3838",
    borderColor: "#9b3838",
    marginTop: "7px",
    marginBottom: "7px",
  }
  
class ContactForm extends React.Component {
 constructor(props) {
   super(props)
   this.state = {
     buttonDisabled: true,
     message: { name: "", fromEmail: "", subject: "", body: "" },
     submitting: false,
     error: null,
   }
 }

  onClick = async event => {
    event.preventDefault()
    let { name, fromEmail, subject, body } = this.state.message

    if (fromEmail.trim().length === 0) {
      this.setState({
        error: "Please enter a valid email address."
      })
    }
    else {
        this.setState({ submitting: true })

        if (name.trim().length === 0) {
            name = "Not provided"
        }
        if (subject.trim().length === 0) {
            subject = "Not provided"
        }
        if (body.trim().length === 0) {
            body = "Not provided"
        }

        //const postBody = '{"name":"bentz tozer","email":"bentztozer3@gmail.com","phone":"703-555-1212","message":"call me or else!"}'
        //const postBody = `{"name":"name","email":"${fromEmail}","phone":"${subject}","message":"${body}"}`
        const postBody = `{"name":"${name}","email":"${fromEmail}","phone":"${subject}","message":"${body}"}`
        //const postBody = '{"name":"' + name + '","email":"bentztozer3@gmail.com","phone":"703-555-1212","message":"call me or else!"}'
        
        const response = await fetch(functionURL, {
            method: "post",
            headers: {
              "Content-type": "application/json",
            },
            body: postBody,
        })
       
        if (response.status === 200) {
        this.setState({
            error: "Thank you for your submission.  We will respond promptly.",
            submitting: false,
            message: {
            name: "",  
            fromEmail: "",
            subject: "",
            body: "",
            },
        })
        } else {
        const json = await response.json()
        this.setState({
            error: json.error,
            submitting: false,
        })
        }
    }
 }

 onChange = event => {
   const name = event.target.getAttribute("name")
   this.setState({
     message: { ...this.state.message, [name]: event.target.value },
   })
 }
 render() {
   return (
     <>
       <div>{this.state.error}</div>
       <form
         style={{
           display: `flex`,
           flexDirection: `column`,
           maxWidth: `500px`,
         }}
         method="post"
         action={functionURL}
       >
         <label htmlFor="name">Name:</label>
         <input
           type="text"
           name="name"
           id="name"
           value={this.state.message.name}
           onChange={this.onChange}
         ></input>           
         <label htmlFor="fromEmail">Email Address:</label>
         <input
           type="email"
           name="fromEmail"
           id="fromEmail"
           value={this.state.message.fromEmail}
           onChange={this.onChange}
         ></input>
         <label htmlFor="subject">Phone Number:</label>
         <input
           type="tel"
           name="subject"
           id="subject"
           value={this.state.message.subject}
           onChange={this.onChange}
         />
         <label htmlFor="body">Message:</label>
         <textarea
           style={{
             height: `200px`,
           }}
           name="body"
           id="body"
           value={this.state.message.body}
           onChange={this.onChange}
         />
         <button className="btn btn-primary"
           style={buttonStyles}
           type="submit"
           disabled={this.state.submitting}
           onClick={this.onClick}
         >
           Send message
         </button>
       </form>
     </>
   )
 }
}

export default ContactForm
*/