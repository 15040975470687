import * as React from "react"
import { Container, Row, Col } from 'react-bootstrap';

import Header from "../components/header"
import FixedFooter from "../components/footer"
import ContactForm from "../components/contactForm"

// styles
const headingStyles = {
    marginTop: 140,
    marginBottom: 100,
}

// markup
const ContactPage = () => {
  return (
    <main>
      <title>Contact Fieldstone Farm</title>
      <Header></Header>
      <Container style={headingStyles}>
        <Row>
          <div className="col-sm-6">
            <ContactForm/>
          </div>
          <div className="col-sm-6">
            <div className="overflow-hidden justify-content-center">
              <img src="https://api.mapbox.com/styles/v1/mapbox/streets-v11/static/pin-s+833921(-77.726350,39.199690)/-77.68,39.21,10/700x500?access_token=pk.eyJ1IjoiZmllbGRzdG9uZWZhcm0iLCJhIjoiY2w1Y2RoY3luMGdnNjNlcGR4bWw3Y2tqbyJ9.H3SOZMpt5ygmXtm6EhMT5w" 
                alt="Map of Fieldstone Farm in Hillsboro, VA."/> 
            </div>
          </div>
        </Row>
      </Container>
      <FixedFooter/>
    </main>
  )
}

export default ContactPage